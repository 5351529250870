import { i18n } from "@lingui/core"
import React, { useEffect } from "react"

export interface LocalizedPageContext {
  locale?: string
  localeCatalog: Record<string, string>
}

// catalog cache; prevent repeated load of same catalog
const loadedCatalogs: Dictionary<Dictionary<boolean>> = {
  en: {},
  de: {},
}

i18n.load({
  en: {},
  de: {},
})

export { i18n }

/**
 * Determine the page namespace from the pathname
 * Supports nested paths like /catalog/apple-gift-cards/
 */
export function getPageNamespace(pathname: string): string {
  // Remove leading and trailing slashes and split by /
  const parts = pathname.replace(/^\/|\/$/g, "").split("/")

  // Check if first part is a locale code
  if (parts[0] && /^[a-z]{2}$/.test(parts[0])) {
    // Remove locale part
    parts.shift()
  }

  // If there are no parts left, it's the root path (homepage)
  if (parts.length === 0 || parts[0] === "") {
    return "__root_path__"
  }

  // For nested paths, just return the top-level directory or path
  // Ex: "/catalog/apple-gift-cards/" → "catalog"
  // Ex: "/de/catalog/apple-gift-cards/" → "catalog"
  return parts[0]
}

/**
 * Dynamic activation function to load and activate a locale
 * This follows the pattern from Lingui's dynamic loading guide
 */
async function dynamicActivate(
  locale: string,
  namespace: string,
): Promise<void> {
  try {
    if (loadedCatalogs[locale]?.[namespace]) {
      // TODO: remove comments once localization has been fully vetted
      // console.log(`dynamicActivate: skipping ${locale}/${namespace}`)
      return
    }

    if (namespace === "__root_path__") {
      // TODO: handle root path
      // console.log(`dynamicActivate: skipping non-existent homepage catalog`)
      return
    }

    const catalogPath =
      namespace === "common"
        ? `./${locale}/common`
        : `./${locale}/pages/${namespace}`

    const catalog = await import(catalogPath)
    const { messages } = catalog

    // TODO: remove comments once localization has been fully vetted
    // console.log(
    //   `dynamicActivate: loading namespace "${namespace}" messages for "${locale}"`,
    //   messages,
    // )
    i18n.load({
      [locale]: messages,
    })

    if (!loadedCatalogs[locale]) {
      loadedCatalogs[locale] = {}
    }

    loadedCatalogs[locale][namespace] = true
  } catch (error) {
    // TODO: remove comments once localization has been fully vetted
    // eslint-disable-next-line no-console
    console.error(`Failed to load catalog for ${locale}/${namespace}:`, error)
  }

  // console.log("dynamicActivate: loaded catalogs", loadedCatalogs)
}

/**
 * Loads relevant locale during client-side navigation
 */
function useLocaleCatalog(locale: string, pathname: string): void {
  useEffect(() => {
    const pageNamespace = getPageNamespace(pathname)

    // catalogs that are already loaded will no-op
    Promise.all([
      dynamicActivate(locale, "common"),
      dynamicActivate(locale, pageNamespace),
    ])
      .catch(() => {
        // silent catch - we already log in dynamicActivate
      })
      .finally(() => {
        // ensure locale is activated after loading
        // TODO: remove comments once localization has been fully vetted
        // console.log(`useCatalogs finally: activating locale ${locale}`)
        i18n.activate(locale)
      })
  }, [locale, pathname])
}

interface LocaleCatalogProviderProps {
  locale: string
  pathname: string
  children: React.ReactNode
}

export function LocaleCatalogProvider({
  locale,
  pathname,
  children,
}: LocaleCatalogProviderProps): React.ReactNode {
  useLocaleCatalog(locale, pathname)

  return children
}
