import "./src/styles/global.scss"
import "./src/styles/font-faces.scss"

import { RouteUpdateArgs } from "gatsby"

import { SEGMENT_LOADED_EVENT_ID } from "./src/shared-components/seo/use-segment-is-loaded"
import { initWebVitals } from "./src/utils/analytics/web-vitals"
import { isBrowser } from "./src/utils/browser"
import { QUERY_PARAM_CHANGE_EVENT_ID } from "./src/utils/contexts/page-context"

interface SegmentAnalytics extends Record<string, unknown> {
  track: (
    eventName: string,
    properties?: Record<string, unknown>,
    options?: unknown,
  ) => void
  page: (
    name: string,
    properties?: Record<string, unknown>,
    options?: unknown,
  ) => void
}

declare global {
  interface Window {
    pagePath?: string
    analytics?: SegmentAnalytics
  }
}

export { wrapPageElement } from "./gatsby-shared"

export function onRouteUpdate({ location, prevLocation }: RouteUpdateArgs) {
  if (location.search !== prevLocation?.search) {
    const event = new CustomEvent(QUERY_PARAM_CHANGE_EVENT_ID, {
      detail: {
        pathname: location.pathname,
        search: location.search,
      },
    })
    document.dispatchEvent(event)
  }
}

export function onInitialClientRender() {
  if (isBrowser()) {
    if (window.analytics) {
      initWebVitals()
      return
    }

    const handleSegmentLoaded = () => {
      initWebVitals()
      document.removeEventListener(SEGMENT_LOADED_EVENT_ID, handleSegmentLoaded)
    }

    document.addEventListener(SEGMENT_LOADED_EVENT_ID, handleSegmentLoaded)
  }
}
